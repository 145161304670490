
import Sidebar from 'primevue/sidebar'
import {
  useStore
} from 'vuex'
import {
  ref,
  computed,
  defineComponent,
  onMounted
} from 'vue'
import leftMenu from '@/data/local/json/leftmenu'
import reportOnlyMenu from '@/data/local/json/reportmenu'
import DropdownListMenu from '@/views/shared-components/DropdownListMenu.vue'

export default defineComponent({
  name: 'LeftPanel',
  components: {
    Sidebar,
    DropdownListMenu,
  },
  setup() {
    const items = ref(localStorage.getItem('rn') === 'External' ? reportOnlyMenu() : leftMenu())
    const store = useStore()
    const env = process.env
    const version = localStorage.getItem('version')
    const isLoaded = ref(false)

    const activeSlider = computed({
      get: () => store.state.toggleMenu,
      set: (val) => {
        store.dispatch('setToggleMenu', val)
      }
    })
    const isMobile = ref(window.innerWidth > 1024 ? false : true)
    const hiddenSidebar = () => {
      activeSlider.value = false
    }
    const checkProhibitedRoles = (list: any) => {
      if (list.length < 1) {
        return true
      }
      const val = list.indexOf(store.state?.userProfile?.RolesName)
      return val < 0
    }

    onMounted(() => {
      store.dispatch('fetchProfile')
      isLoaded.value = true
    })

    return {
      activeSlider,
      hiddenSidebar,
      items,
      store,
      env,
      isMobile,
      checkProhibitedRoles,
      version,
      isLoaded
    }
  }
})
