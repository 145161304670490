
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import RadioButton from 'primevue/radiobutton'
import { getCurrentInstance, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import apiUseCase from '@/usecase/apiUseCase'
import { cloneDeep } from 'lodash'
import Popper from 'vue3-popper'

export default {
  components: {
    Card,
    Button,
    InputText,
    Textarea,
    Popper,
    // InputNumber,
    // Dropdown,
    // RadioButton
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const val = ref(true)
    const data = ref([]) as any
    const dataset = ref() as any
    const dataForm = ref([]) as any
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const endpoint = 'settings/v1/GlobalSetting'
    const sectionTitle = ref([
      {
        key: 1,
        title: 'Pengaturan Data'
      },
      {
        key: 2,
        title: 'Pengaturan Pesan (Kata-kata)'
      },
      {
        key: 3,
        title: 'Pengaturan Khusus Bandara'
      },
    ])

    const compare = (after: any, before: any) => {
      // console.log('a', after)
      // console.log('b', before)
      if (after.GroupOrder < before.GroupOrder) {
        return -1
      }
      if (after.GroupOrder > before.GroupOrder) {
        return 1
      }
      return 0
    }

    const grouping = (datas: any) => {
      const datasetTemp = datas.reduce((x: any, y: any) => {
        x[y.GroupCode] = x[y.GroupCode] || []
        x[y.GroupCode].push(y)
        return x
      }, Object.create(null))

      dataset.value = Object.keys(datasetTemp).map((key) => [key, datasetTemp[key]])

      dataset.value.forEach((cobaEl: any) => {
        // eslint-disable-next-line eqeqeq
        const titleTemp = sectionTitle.value.find((x: any) => x.key == cobaEl[0])
        cobaEl.push(titleTemp?.title)
        cobaEl[1].sort(compare)
      })
      console.log('cobaAfter', dataset.value)
    }

    const getAllData = () => {
      store.dispatch('showLoading')
      apiUseCase.get(endpoint).then((response) => {
        console.log('response', response)
        // dataSource.value = response.result
        data.value = response.result
        data.value.forEach((element: any) => {
          if (element.Type === 'NUM') {
            element.Value = Number(element.Value)
          }
        })
        grouping(data.value)
        console.log('dataset', dataset.value)
      })
      store.dispatch('hideLoading')
    }

    const checkResponse = (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: 'Data Gagal Disimpan',
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: 'Data berhasil Disimpan',
          group: 'bc',
          life: 3000
        })
      }
      getAllData()
      store.dispatch('globalSetting/fetchGlobalSetting')
      store.dispatch('hideLoading')
    }

    const processStoreData = async () => {
      store.dispatch('showLoading')
      const dataSend = cloneDeep(data.value)

      console.log('dataSend', dataSend)
      dataSend.forEach((element: any) => {
        element.Value = element.Value.toString()
      })

      console.log('datasend', dataSend)
      const response = await apiUseCase.postOrPut(endpoint, null, dataSend)
      checkResponse(response)
    }

    const submitData = () => {
      console.log('dataForm', data)
      $confirm.require({
        header: 'Edit Pengaturan Global',
        message: 'Apakah anda yakin ingin menggantinya?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          processStoreData()
        }
      })
      // processStoreData()
    }

    onMounted(() => {
      getAllData()
    })

    return {
      val,
      sectionTitle,
      dataset,
      submitData
    }
  }
}
