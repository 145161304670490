import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "layout-wrapper" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "w-screen h-screen flex items-center",
  style: {"background":"rgba(0, 0, 0, 0.2)","z-index":"10490","position":"fixed","top":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderPanel = _resolveComponent("HeaderPanel")!
  const _component_LeftPanel = _resolveComponent("LeftPanel")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_ChangePasswordDialog = _resolveComponent("ChangePasswordDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_HeaderPanel),
      _createVNode(_component_LeftPanel),
      _createElementVNode("div", {
        class: _normalizeClass(["layout-main", $setup.store.state.toggleMenu && $setup.store.state.toggleMinimizeMenu ? 'active minimized' : $setup.store.state.toggleMenu && !$setup.store.state.toggleMinimizeMenu ? 'active' : '']),
        ref: "layoutMain",
        style: _normalizeStyle(`${($setup.route.meta.noSpaceHorizontal && $setup.store.state.device.width < 768) ? 'padding-left: 0 !important; padding-right: 0 !important;' : ''}`)
      }, [
        _createElementVNode("div", _hoisted_2, [
          ($setup.breadcrumbs.length > 0)
            ? (_openBlock(), _createBlock(_component_Breadcrumb, {
                key: 0,
                class: "mb-3",
                ref: "breadcrumbMain",
                list: $setup.breadcrumbs
              }, null, 8, ["list"]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, { "enter-active-class": "animate__animated animate__delay animate__fast animate__fadeInRight" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "bodyMain" }, null, 512))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ])
      ], 6),
      _createVNode(_component_ConfirmDialog, {
        draggable: false,
        class: "dialog-confirm"
      })
    ]),
    _createVNode(_Transition, {
      name: "load-transition",
      "enter-active-class": "animate__animated animate__fast animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster"
    }, {
      default: _withCtx(() => [
        (_ctx.$store.state.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ProgressSpinner, { class: "h-16" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ChangePasswordDialog)
  ], 64))
}