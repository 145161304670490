
import Breadcrumb from '@/views/components/breadcrumbs/BreadcrumbMain.vue'
import LeftPanel from '@/views/layouts/panel/LeftPanel.vue'
import HeaderPanel from '@/views/layouts/panel/HeaderPanel.vue'
import '@/assets/styles/scss/layout.scss'
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import ChangePasswordDialog from '@/views/components/modal/HjpChangePasswordModal.vue'
import environment from '@/configs/environtment'

export default {
  name: 'Main',
  components: {
    LeftPanel,
    HeaderPanel,
    Breadcrumb,
    ProgressSpinner,
    ChangePasswordDialog
  },
  setup() {
    const store = useStore()
    const route: any = useRoute()
    const breadcrumbs = computed(() => store.state.ui.breadcrumbs)

    const checkLocalVersion = () => {
      const version = localStorage.getItem('version')
      if (version !== environment.getVersion() && store.state.device.isWeb) {
        localStorage.setItem('version', environment.getVersion())
        window.location.reload()
      }
    }

    onMounted(() => {
      checkLocalVersion()
      // console.log('store on main', store.state)
      // console.log('breadxrimbs', breadcrumbs)
    })

    return {
      breadcrumbs,
      store,
      route
    }
  }
}
