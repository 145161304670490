import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormExample = _resolveComponent("FormExample")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      columns: $setup.tabelParams.columnsTable,
      endpoint: $setup.tabelParams.endpoint
    }, {
      id: _withCtx(({ slotProps }) => [
        _createTextVNode(_toDisplayString(slotProps.data.id), 1)
      ]),
      form: _withCtx(() => [
        _createVNode(_component_FormExample)
      ]),
      _: 1
    }, 8, ["columns", "endpoint"])
  ]))
}