
import { defineComponent, reactive, ref, getCurrentInstance, onMounted } from 'vue'
import { IListUser } from '@/utils/interfaces/iConductor'
import apiUseCase from '@/usecase/apiUseCase'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
// Components
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import Card from 'primevue/card'
import InputSwitch from 'primevue/inputswitch'
import OverlayPanel from 'primevue/overlaypanel'
import ListActions from '@/views/components/list/ListActions.vue'
import InlineSvg from 'vue-inline-svg'
import HjpConfirmationModal from '@/views/components/modal/HjpConfirmationModal.vue'
import IResponse from '@/utils/interfaces/iResponse'

const endpoint = '/management/v1/User'
const resetpasswordEndpoint = '/management/v1/Password/Reset'

const ConductorTable = defineComponent({
  name: 'ListConductor',
  components: {
    HjpDataList,
    Card,
    InputSwitch,
    OverlayPanel,
    ListActions,
    InlineSvg,
    HjpConfirmationModal
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const isLoading = ref(true)
    const isCondectur = ref(route.name === 'conductor-account')
    const role = ref(null) as any
    const store = useStore()
    const params = reactive({
      endpoint: `${endpoint}/all-user`,
      isOpenNewPage: true,
      baseRoute: '',
      buttonTitle: '',
      headerTitle: '',
      filters: [] as any,
      confirmDialogDelete: {
        title: 'Hapus Akun Kondektur?',
        subTitle: 'Keterangan hapus jenis penumpang',
        btnSubmitTitle: 'Hapus Akun Kondektur',
      },
      fieldSearch: ['name']
    })
    const dataRow = ref(null) as any
    const op = ref()
    const attrModalConfirmation = reactive({
      show: false,
      title: `Reset Password ${role.value}`,
      subTitle: '',
      btnSubmitTitle: 'Reset Password',
      imgContent: require('@/assets/img/icon/change-password.svg')
    })
    const selectedId = ref(null) as any

    const showDropDown = (evt: any, item: IListUser | null) => {
      dataRow.value = item
      op.value.toggle(evt)
    }
    const onDelete = () => {
      store.dispatch('storeRowDataListDl', dataRow.value)
      store.dispatch('setShowModalConfirmationDl', true)
    }
    const onEdit = (id: string) => {
      // console.log('onEdit')
      router.push({
        name: `${route.name as string}-form`,
        params: {
          id
        }
      })
    }
    const onChangeActivate = async (item: IListUser) => {
      store.dispatch('showLoading')
      try {
        const { error } = await apiUseCase.patch(endpoint, item.Id, {
          IsActive: item.IsActive
        })
        if (!error) {
          let msgActiv = 'diaktifkan'
          if (!item.IsActive) {
            msgActiv = 'dinonaktifkan'
          }
          const message = `Akun ${role.value} ${item.Fullname} berhasil ${msgActiv}`
          $toast.add({
            severity: 'success',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          store.dispatch('hideLoading')
        }
      } catch (err) {
        store.dispatch('hideLoading')
      }
    }

    const setDataRowOnRedux = () => {
      console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataTable', dataRow.value)
      console.log('store', store.state)
    }

    const resetPassword = (id: any) => {
      console.log('id', id)
      setDataRowOnRedux()
      selectedId.value = id
      attrModalConfirmation.show = true
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = () => {
      console.log('submit')
      store.dispatch('showLoading')
      apiUseCase.postOrPut(resetpasswordEndpoint, selectedId.value, null).then(async (res: IResponse) => {
        console.log('response delete', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            // summary: 'Tidak dapat reset password',
            detail: 'Tidak dapat reset password. silahkan coba kembali beberapa saat lagi.',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          // eslint-disable-next-line no-use-before-define
          await sendWhatsappNotif(res.result)
          $toast.add({
            severity: 'success',
            summary: 'Berhasil reset password',
            detail: 'Password berhasil direset. silahkan periksa whatsapp anda',
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const sendWhatsappNotif = (val: any) => {
      store.dispatch('sendWhatsapp', {
        phoneNumber: store.state.hjpTable.modal.data.PhoneNumber,
        message: `Halo, Terimakasih sudah menghubungi Admin HJP. Kami menerima permintaan untuk reset password akun anda.
Silahkan masuk dengan password yang telah kami kirimkan.

Berikut adalah password baru Anda : ${val}

Terima Kasih

Salam,
Admin HJP`
      }).then((res) => {
        console.log('berhasil kirim wa', res)
      }).catch((err) => {
        if (err.response) {
          $toast.add({
            severity: 'error',
            detail: err.response.data.error,
            group: 'bc',
            closable: false,
            life: 3000
          })
          hideDialogConfirmation()
          store.dispatch('hideLoading')
        }
      })
    }

    const initRole = () => {
      if (route.name === 'conductor-account') {
        role.value = 'Kondektur'
        params.baseRoute = 'conductor-account'
        params.buttonTitle = `Tambah ${role.value}`
        params.headerTitle = `Akun ${role.value}`
        params.filters = [['roles', '=', 'Condectur']]
        attrModalConfirmation.title = `Reset Password ${role.value}`
        attrModalConfirmation.subTitle = `Reset password akan menggantikan password ${role.value.toLowerCase()} saat ini`
      } else if (route.name === 'controller-account') {
        role.value = 'Kontroler'
        params.baseRoute = 'controller-account'
        params.buttonTitle = `Tambah ${role.value}`
        params.headerTitle = `Akun ${role.value}`
        params.filters = [['roles', '=', 'Controller']]
        attrModalConfirmation.title = `Reset Password ${role.value}`
        attrModalConfirmation.subTitle = `Reset password akan menggantikan password ${role.value.toLowerCase()} saat ini`
      } else if (route.name === 'driver-account') {
        role.value = 'Sopir'
        params.baseRoute = 'driver-account'
        params.buttonTitle = `Tambah ${role.value}`
        params.headerTitle = `Akun ${role.value}`
        params.filters = [['roles', '=', 'Driver']]
        attrModalConfirmation.title = `Reset Password ${role.value}`
        attrModalConfirmation.subTitle = `Reset password akan menggantikan password ${role.value.toLowerCase()} saat ini`
      } else if (route.name === 'helper-account') {
        role.value = 'Pembantu'
        params.baseRoute = 'helper-account'
        params.buttonTitle = `Tambah ${role.value}`
        params.headerTitle = `Akun ${role.value}`
        params.filters = [['roles', '=', 'Helper']]
        attrModalConfirmation.title = `Reset Password ${role.value}`
        attrModalConfirmation.subTitle = `Reset password akan menggantikan password ${role.value.toLowerCase()} saat ini`
      }
      isLoading.value = false
    }

    onMounted(() => {
      initRole()
    })

    return {
      params,
      showDropDown,
      op,
      onDelete,
      onEdit,
      dataRow,
      onChangeActivate,
      isCondectur,
      resetPassword,
      attrModalConfirmation,
      hideDialogConfirmation,
      onSubmitConfirmation,
      role,
      isLoading
    }
  }
})

export default ConductorTable

