
import DataTable from '@/views/components/table/HjpDataTable.vue'
import FormExample from '@/views/pages/coba/FormExample.vue'
import { ref } from 'vue'

export default {
  components: {
    DataTable,
    FormExample
  },
  setup() {
    const tabelParams = ref({
      endpoint: '/posts',
      columnsTable: [
        {
          field: 'userId',
          headerName: 'User Id',
        },
        {
          field: 'id',
          headerName: 'Id',
          isRender: true,
        },
        {
          field: 'title',
          headerName: 'Judulnya',
        },
        {
          field: 'body',
          headerName: 'Deskripsi',
          sortable: true
        },
      ]
    })
    return {
      tabelParams
    }
  },
}
